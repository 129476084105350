body {
  background-color: #141414;
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
}

.container {
  margin: 0 50px;
}

.navbar {
  height: 50px;
  width: 100%;
  position: sticky;
  top: 0;
}

.hero {
  width: 100%;
  margin-top: -100px;
  padding: 0;
}

.info-section {
  position: absolute;
  margin-top: 30%;
  margin-left: 50px;
  width: 600px;
}

.movie-section {
  display: flex;
}

.button-section {
  display: flex;
}

.button {
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  margin: 3px;
}

.more {
  background-color: rgba(255,255,255, 0.5);
}

.card {
  width: 230px;
  border-radius: 5px;
  margin: 2px;
  transition: transform 500ms;
  position: relative;
  display: block;
}

video {
  width: 100%;
  border-radius: 5px;
}

.hero-video {
  width: 100%;
  float: left;
  top: 0;
  padding: none;
  margin-bottom: 30px;
}

video::-webkit-media-controls {
  display: none;
}

.movie-section:focus-within .card,
.movie-section:focus, .card {
  transform: translate(-25%);
  z-index: 1;
}

.card:focus ~ .card,
.card:hover ~ .card {
  transform: translateX(25%)
}

.movie-section .card:focus,
.movie-section .card:hover {
  transform: scale(1.5);
  z-index: 1;
  background-color: #212121;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.5),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.5);
}

.more-button {
  height: 100%;
  display: flex;
  padding: 40px 10px;
  border-radius: 0 10px 10px 0;
}

.fas {
  margin: 0 5px;
}

.logo {
  width: 88px;
  height: 22px;
  background-image: url('https://i.imgur.com/Y9dwM3s.png');
  background-size: 88px;
  margin: 5px;
}

a {
  text-decoration: none;
  color: #fff;
}

.more-button:hover {
  background-color: rgb(255,255,255, 0.5);
}

.page-end {
  width: 100%;
  height: 300px;
}

ul {
  display: flex;
  list-style-type: none;
}

li {
  margin: 0 10px;
}

.info-box {
  width: 100%;
  z-index: 5;
  padding: 10px;
}

